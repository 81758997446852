body {
    color: #0c3663;
    font-family: 'Nunito Sans', sans-serif !important;
}
body > iframe {
    display: none !important;
}
.contact-section {
    padding: 20px 0;
}
.appie-page-title-area {
    height: 300px;
    background: #183560;
}
.appie-header-area.appie-header-page-area.appie-sticky.sticky {
    background: #183560;
}
/* .appie-page-title-item .title, .appie-page-title-item nav ol li a {
    color: #222222;
} */
.contact-form input[type="text"], .contact-form input[type="date"], .contact-form input[type="password"], .contact-form input[type="number"], .contact-form textarea, .contact-form select, .contact-form input[type="email"] {
    width: 100%;
    height: 56px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 30px;
    margin-bottom: 20px;
    transition: all 0.3s ease;
}
.contact-form input[type="text"]:disabled, .contact-form input[type="email"]:disabled, .contact-form input[type="number"]:disabled {
    background-color: #d0d0d0 !important;
    font-weight: bold;
}
.contact-form textarea {
    height: 100px;
}
.appie-hero-thumb::before {
    background: none;
}
.appie-hero-area {
    padding: 90px 0 30px;
}
.upload-container {
    background-color: #f5f5f7;
    /* border: solid thin #d0d0d0; */
    padding: 20px 30px;
}
label {
    color: #686875;
}
.contact-form input[type="button"] {
    height: 36px;
    border-radius: 3px;
    background: #183560;
    color: #fff;
    text-align: center;
    border: none;
    outline: none;
    padding: 0 36px;
    font-size: 13px;
    line-height: 36px;
    font-weight: 600;
    transition: all 0.3s ease;
}
.contact-form input[type="button"]:disabled {
    background-color: #a0a0a0;
}
.button-option {
    margin: -10px -15px 10px -15px !important;
}
.button-option button {
    background: #183560;
    width: 100%;
    margin: 5px 0px;
    height: 60px;
}
.error-container {
    text-align: right;
    margin: -15px 0 10px;
}
.error-container .text-error, .text-danger {
    font-size: 12px;
    color: rgb(133, 13, 13) !important;
}
.text-success {
    font-size: 12px;
    color: #183560 !important;
}

.alert-response {
    background-color: #f0f0f0;
    padding: 30px;
    border-radius: 10px;
}
.alert-response h4, .alert-response p {
    color: #183560;
}
.alert-response p {
    margin: 0;
}
.label-sm {
    font-size: 11px;
    margin-bottom: 2px;
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color:    #b0b0b0 !important;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #b0b0b0 !important;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #b0b0b0 !important;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color:    #b0b0b0 !important;
}
::-ms-input-placeholder { /* Microsoft Edge */
   color:    #b0b0b0 !important;
}

::placeholder { /* Most modern browsers support this now. */
   color:    #b0b0b0 !important;
}
.box {
    padding: 20px;
    background-color: #f0f0f0;
    margin-bottom: 10px;
    color: #183560
}
.btn-link {
    background: transparent !important;
    background-color: transparent !important;
    border: transparent !important;
    color: #183560;
    text-decoration: underline;
    font-size: 14px;
}

.appie-hero-content p {
    font-size: 20px;
}
.appie-hero-content .header {
    color: #cbd1db;
    font-size: 25px;
}
.appie-hero-content .logo {
    margin: 20px -4px;
}
.appie-hero-content ul li a {
    background-color: #fff;
    border: none;
    padding: 0;
}

.appie-hero-content ul {
    margin: 20px 0 100px;
}
.sb-social {
    display: flex;
    align-items: center;
    color: #6a6dc3;
    font-size: 16px;
}
.sb-social .text {
    padding: 1px 5px 0;
}
.footer-widget-info ul li a i {
    color: #0c3663;
}
.footer-widget-info ul li a i img {
    height: 17px;
    margin-top: -5px;
}

.section-title h2{
    color: #222222;
}
.section-title p {
    font-size: 24px;
}

.appie-service-area {
    background: #fff;
    background-image: none;
}
.appie-service-area .section-title h2{
    margin-bottom: 40px;
}
.appie-service-area .section-title p{
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 10px;
}
.appie-service-area .section-title p .blue {
    color: #0c3663;
    font-weight: bold;
}
.appie-single-service p {
    font-size: 22px;
    font-weight: 300;
}
.appie-single-service .img-head {
    text-align: center;
    margin-bottom: 60px;
}
.appie-single-service .img-head img {
    height: 120px;
}
.right-border {
    border-right: solid 2px #222222;
}

.appie-mission-area .card {
    padding: 0px;
    border: none;
    border-radius: 20px;
    overflow: hidden;
    min-height: 450px;
    margin-top: 5px;
}
.appie-mission-area .card .header {
    background-color: #193560;
    height: 200px;
    text-align: center;
}
.appie-mission-area .card .header img{
    margin-top: 20px;
    height: 180px;
}
.card-grey {
    background-color: #f0f1f1;
}
.card-grey .section-title {
    padding: 0px 20px 40px 20px;
}

.appie-testimonial-area {
    background-color: #fff;
    background-image: none;
}
.appie-testimonial-slider-rtl .slick-arrow, .appie-testimonial-slider .slick-arrow {
    color: #222222;
}
.appie-blog-item {
    padding: 0 10px;
}

.card-download {
    min-height: 200px !important;
    background-color: #f0f1f1;
}

.mitra .appie-hero-content .header {
    color: #126a9f;
    font-size: 34px;
    line-height: 40px;
    font-weight: bold;
}

.sb-yellow {
    color: #e0bf56;
    font-weight: bold;
}
.sb-blue {
    color: #0c3662;
    font-weight: bold;
}

.mitra .appie-hero-content p {
    font-size: 30px;
    line-height: 36px;
    font-weight: lighter;
}

.btn-mitra {
    background-color: #183560;
    border-color: #183560;
    width: 300px;
    font-weight: bold;
    font-size: 24px;
}

@media (max-width: 766px) {
    .sb-social {
        justify-content: center;
    }
}

.appie-blog-item .thumb img {
    object-fit: cover;
}

.contact-form input[type="radio"] {
    width: 16px;
    height: 16px;
    font-size: 14px;
    line-height: 56px;
    font-weight: 400;
    border-radius: 3px;
    border: none;
    background: #f5f5f7;
    color: #7e7c87;
    outline: none;
    border: 1px solid transparent;
    padding: 0 30px;
    transition: all 0.3s ease;
}
.radio-container {
    display: flex;
    align-items: center;
}

.event-mitra-label {
    color: #183560;
}
.event-time-label {
    color: #a0a0a0;
    font-size: 12px;
}
.event-description {
    padding-top: 20px;
}
.blogpage-section {
    padding-top: 50px
}

.btn-relawan {
    background-color: #3d73c3;
    border-color: #3d73c3 !important;
}

.tc-ol {
    margin: 0 30px !important;
}

@media (max-width: 1080px), (pointer:none), (pointer:coarse) {
    .contact-section {
        padding: 10px 0;
    }
    .contact-section .container, .contact-form {
        padding: 10px;
    }
    .contact-form .col-md-12 {
        padding: 0 10px;
    }
    .contact-form input[type="text"], .contact-form input[type="date"], .contact-form input[type="password"], .contact-form input[type="number"], .contact-form textarea, .contact-form select, .contact-form input[type="email"] {
        padding: 0 10px;
    }
    .upload-container {
        padding: 10px;
    }
    .error-container {
        margin-top: 0px;
    }
    .tc-ol {
        margin: 0 10px !important;
    }
    .makeStyles-dialog-5 {
        max-width: 100% !important;
        width: 100% !important;
    }
    .MuiDialog-paper {
        margin: 10px !important;
    }
    .MuiDialog-paperScrollPaper {
        max-height: calc(100% - 30px) !important;
    }
}

.title {
    margin: 20px 0px 10px;
    font-size: 20px;
}

.WordSection1 ul {
    list-style-type: disc;
    padding: 0 15px;
}

.MsoNormal {
    text-align: justify;
    text-justify: inter-word;
}